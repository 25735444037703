import { getLocalStorageItem } from '@shared/utils'

export const DEFAULT_VIDEO_CONSTRAINTS = {
  width: 960,
  height: 540,
  frameRate: parseInt(getLocalStorageItem('twilioFrameRate')) || 24,
  codec: getLocalStorageItem('twilioCodec')  || 'VP8',
}

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'QidaVideo-selectedAudioInput'
export const SELECTED_AUDIO_OUTPUT_KEY = 'QidaVideo-selectedAudioOutput'
export const SELECTED_VIDEO_INPUT_KEY = 'QidaVideo-selectedVideoInput'

export const caixaBankButton =
  'self-center bg-caixa-primary border-caixa-primary hover:border-caixa-primary hover:text-caixa-primary'

export const browsersType = {
  Chrome: 'Chrome',
  Firefox: 'Firefox',
  Edge: 'Edge',
  Safari: 'Safari',
}

export const osType = {
  Android: 'Android',
  iOS: 'iOS',
  Mac: 'Mac',
  Windows: 'Windows',
  Linux: 'Linux',
}

export const determineContentKey = ({
  isSupported = true,
  isRoomFinished = false,
  roomState = '',
  userWantJoin = false,
}) => {
  if (!isSupported) return 'unsupported'
  if (isRoomFinished && roomState === 'disconnected') return 'endText'
  if (userWantJoin) return 'meetScreen'
  return 'previewScreen'
}

export const VideoCallPermissions = Object.freeze({
  granted: 'granted',
  denied: 'denied',
  loading: 'loading',
  pending: 'pending',
  error: 'error',
})

export const VideoCallDevices = Object.freeze({
  audiooutput: 'audiooutput',
  audioinput: 'audioinput',
  videoinput: 'videoinput',
})
