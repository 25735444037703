import { SRModal } from '@sr/ui'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { BUTTON_COLORS, Button } from '@shared/ui'
import './styles.scss'
import { disabledButton } from './styles'

type ConfirmModalProps = {
  onAction?: () => void
  onClose: () => void
  children: JSX.Element
  onCancelText?: string
  onConfirmText?: string
  selectedValue?: string | null
  title?: string
  location?: string
  textColor?: string
  disabledValidation?: boolean
}

export const SRConfirmModal: FC<ConfirmModalProps> = ({
  onAction,
  onClose,
  children,
  onCancelText = 'common:exit',
  onConfirmText = 'common:confirm',
  selectedValue = '',
  title = '',
  location,
  textColor,
  disabledValidation = false,
}) => {
  const { t } = useTranslation()

  return (
    <SRModal onClose={onClose} title={title}>
      <div className={`${title ? '!items-baseline' : ''} sr-confirm-modal`}>
        <div>{children}</div>
      </div>
      <div
        className={`${
          location ? `sr-confirm-modal__justify_${location}` : ''
        } sr-confirm-modal__button`}
      >
        <Button
          className={
            textColor
              ? `sr-confirm-modal__text_${textColor} sr-confirm-modal__border_${textColor}`
              : ''
          }
          color={
            disabledValidation
              ? BUTTON_COLORS.transparent
              : BUTTON_COLORS.secondary
          }
          onClick={onClose}
        >
          {t(onCancelText)}
        </Button>
        <Button
          onClick={onAction}
          className={`${
            !selectedValue && disabledValidation && disabledButton
          }`}
        >
          {t(onConfirmText)}
        </Button>
      </div>
    </SRModal>
  )
}
