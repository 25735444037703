import { SRSelect, SRSelectOptions } from '@sr/ui'
import { FC } from 'react'
import { Button, BUTTON_SIZES } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import {
  ActionsBlock,
  ActionsBlockContentTwoCols,
  ActionsBlockTitle,
} from '../../../styles'

type ChangeOpBlockProps = {
  srList: SRSelectOptions
  action: () => void
}

export const ChangeOpBlock: FC<ChangeOpBlockProps> = ({
  srList = [],
  action,
}) => {
  const { t } = useTranslation()
  const TRANSLATION_BASE_KEY = 'userArea.profile.actions.changeOp'
  const { isLoading } = useLayoutContext()

  if (!srList.length) return

  return (
    <ActionsBlock>
      <ActionsBlockTitle>
        {t(`${TRANSLATION_BASE_KEY}.title`)}
      </ActionsBlockTitle>
      <ActionsBlockContentTwoCols>
        {!!srList.length && (
          <SRSelect options={srList} name={'socialReferent'} />
        )}
        <Button onClick={action} size={BUTTON_SIZES.small} disabled={isLoading}>
          {t('common:change')}
        </Button>
      </ActionsBlockContentTwoCols>
    </ActionsBlock>
  )
}
