export enum ProfileForm {
  phone = 'phone',
  email = 'email',
  street = 'street',
  postalCode = 'postalCode',
  locality = 'locality',
  region = 'region',
  preferredLanguage = 'preferredLanguage',
  sex = 'sex',
  identification = 'identification',
  birthDay = 'birthDay',
}
