import tw from 'tailwind-styled-components'

export const ScheduleAgendaContainer = tw.div`
    py-9
    col-start-5
    col-end-13
`

export const dateBannerClasses = `
    bg-modal-overlay
    mb-9
`
