import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import { Form, getFiltersObject } from '@shared/ui'
import { originalFormatDate, useRouteState, getLocalStorageItem } from '@shared/utils'
import { SRUserName } from '@sr/ui'
import { useLayoutContext } from '../../common'
import {
  ConfirmationModal,
  ScheduleAgenda,
  ScheduleNoDigitalCheckbox,
} from './components'
import { FORM_NAME } from './enums'
import { ScheduleRouteState } from './state'

import { formContainerClasses, ScheduleContainer } from './styles'

export const Schedule = observer(() => {
  const { id } = useParams()
  const { setIsLoading } = useLayoutContext()

  const {
    policy,
    isLoading,
    setScheduleFilters,
    schedules,
    savePolicyOnSchedule,
    loadDependencies,
    isMod,
  } = useRouteState(ScheduleRouteState, {
    policyId: id,
  })

  const methods = useForm()
  const { watch, setValue, handleSubmit, reset } = methods
  const noDigital = watch(FORM_NAME.noDigital)

  const firstAppointmentDate = policy?.firstAppointmentDate

  const [isModalOpen, setIsModalOpen] = useState()
  const [modifyDate, setModifyDate] = useState()

  const dateSelected = watch(FORM_NAME.firstAppointmentDate) || modifyDate

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  useEffect(() => {
    setValue(FORM_NAME.firstAppointmentDate, null)
    setScheduleFilters(getFiltersObject({ noDigital }))
  }, [noDigital])

  useEffect(() => {
    if (policy) {
      reset({
        [FORM_NAME.noDigital]: policy.noDigital,
      })
    }
    if (isMod) {
      setModifyDate(originalFormatDate(firstAppointmentDate))
    }
  }, [policy])

  if (!policy) return

  const onSubmit = handleSubmit(async (form) => {
    const { originQida } = form
    form.firstAppointmentDate = getLocalStorageItem('dateSelected')    
    await savePolicyOnSchedule(form, { avoidUpdate: true, originQida })
    await loadDependencies()
    setIsModalOpen(true)
  })

  const onClose = () => {
    setIsModalOpen(false)
  }

  const key = isMod ? 'common:modifyAppointment' : 'common:createAppointment'

  return (
    <ScheduleContainer>
      <SRUserName fullName={policy.fullName} />
      <Form className={formContainerClasses} methods={methods}>
        <ScheduleNoDigitalCheckbox
          isMod={isMod}
          setModifyDate={setModifyDate}
          name={FORM_NAME.noDigital}
        />
        <ScheduleAgenda
          buttonText={key}
          onSubmit={onSubmit}
          dateSelected={dateSelected || modifyDate}
          firstDateSelected={firstAppointmentDate}
          noDigital={noDigital}
          schedules={schedules}
          isMod={isMod}
          setModifyDate={setModifyDate}
          modifyDate={modifyDate}
        />
      </Form>
      {isModalOpen && <ConfirmationModal onClose={onClose} />}
    </ScheduleContainer>
  )
})

