import {
  APPOINTMENT_TYPES,
  FILTER_OPTIONS_TYPE,
  SCHEDULE_FILTER_OPTION,
  EMPLOYEE_STATE,
} from '@shared/data-access'
import {
  convertDateToSpanishZone,
  convertDateToUtc,
  getDateFormatted,
  getToday,
} from '@shared/utils'
import { INITIAL_REPORT_TEST_NAME } from '@sr/routes/initial-report/enums'
import { flow, getEnv, getRoot, isRoot } from 'mobx-state-tree'

export const actions = (self) => ({
  loadPoliciesByRequestDate: flow(
    function* loadPoliciesByRequestDate(startDate, endDate) {
      self.setLoading?.('loadPoliciesByRequestDate')
      try {
        const policies = yield getRoot(self).PolicyStore.fetchAll({
          noClear: true,
          filter: {
            Policy: {
              requestDate: {
                value: [startDate, endDate],
                options: { type: FILTER_OPTIONS_TYPE.range },
              },
            },
          },
        })

        if (policies) {
          self.policies = policies
        }
      } finally {
        self.removeLoading?.('loadPoliciesByRequestDate')
      }
    },
  ),
  loadPoliciesNoScheduled: flow(function* loadPoliciesNoScheduled() {
    self.setLoading?.('loadPoliciesNoScheduled')
    try {
      const endDate = getDateFormatted(getToday(), 'YYYY-MM-DD')
      const policies = yield getRoot(self).PolicyStore.fetchAll({
        noClear: true,
        filter: {
          Policy: {
            scheduledDate: {
              value: SCHEDULE_FILTER_OPTION.true,
              options: { type: FILTER_OPTIONS_TYPE.isnull },
            },
            requestDate: {
              value: endDate,
              options: { type: FILTER_OPTIONS_TYPE.lte },
            },
          },
        },
      })

      if (policies) {
        self.noScheduledPolicies = policies
        return policies
      }
    } finally {
      self.removeLoading?.('loadPoliciesNoScheduled')
    }
  }),
  loadPoliciesNoDigital: flow(function* loadPoliciesNoDigital() {
    self.setLoading?.('loadPoliciesNoDigital')
    try {
      const policies = yield getRoot(self).PolicyStore.fetchAll({
        noClear: true,
        filter: {
          Policy: {
            noDigital: {
              value: true,
            },
          },
        },
      })

      if (policies) {
        self.noDigitalPolicies = policies
        return policies
      }
    } finally {
      self.removeLoading?.('loadPoliciesNoDigital')
    }
  }),
  loadPolicy: flow(function* loadPolicy() {
    self.setLoading?.('loadPolicy')
    try {
      if (isRoot(self)) return
      const policy = yield getRoot(self).PolicyStore.fetch(self.policyId, {
        include: 'social_referent,subscription_office',
      })

      if (policy) {
        self.policy = policy
      }
    } finally {
      self.removeLoading?.('loadPolicy')
    }
  }),
  savePolicyOnSchedule: flow(function* savePolicy(data, options = {}) {
    self.setLoading?.('savePolicy')
    try {
      if (data.firstAppointmentDate)
        data.firstAppointmentDate = convertDateToSpanishZone(
          data.firstAppointmentDate,
        )

      self.policy.update(data)
      if (options?.originQida) {
        const response = yield getRoot(self).PolicyStore.patch(
          {
            id: self.policyId,
            ...data,
          },
          {
            ...options,
            params: { appointmentModificationOrigin: 2 },
          },
        )
        if (response) {
          self.policy = response
        }
      } else {
        const response = yield getRoot(self).PolicyStore.patch(
          {
            id: self.policyId,
            ...data,
          },
          options,
        )
        if (response) {
          self.policy = response
        }
      }
    } finally {
      self.removeLoading?.('savePolicy')
    }
  }),
  savePolicy: flow(function* savePolicy(data, options = {}) {
    self.setLoading?.('savePolicy')
    try {
      if (data.firstAppointmentDate)
        data.firstAppointmentDate = convertDateToSpanishZone(
          data.firstAppointmentDate,
        )

      self.policy.update(data)
      yield self.policy.save(options)
    } finally {
      self.removeLoading?.('savePolicy')
    }
  }),
  savePolicyOnlyData: flow(function* savePolicyOnlyData(data, options = {}) {
    self.setLoading?.('savePolicyOnlyData')
    try {
      const response = yield getRoot(self).PolicyStore.patch({
        id: self.policyId,
        ...data,
      })

      if (response) {
        self.policy = response
      }
    } finally {
      self.removeLoading?.('savePolicyOnlyData')
    }
  }),
  loadClientByPolicy: flow(function* loadClientByPolicy() {
    self.setLoading?.('loadClientByPolicy')
    try {
      if (isRoot(self)) return
      const client = yield getRoot(self).ClientStore.get(null, {
        include: 'person',
        withoutId: true,
        customUrl: `/clients/`,
        filter: {
          Client: {
            principal_policy: {
              value: self.policyId,
            },
          },
        },
      })

      if (client) {
        self.client = client
      }
    } finally {
      self.removeLoading?.('loadClientByPolicy')
    }
  }),
  loadSocialReferent: flow(function* loadSocialReferent() {
    self.setLoading?.('loadSocialReferent')
    try {
      if (self.socialReferentId) {
        self.socialReferent = yield getRoot(self).SocialReferentStore.get(
          self.socialReferentId,
        )
      } else {
        self.socialReferent = yield getRoot(self).SocialReferentStore.fetchAll({
          search: self.search || '',
          noClear: true,
        })
      }
    } finally {
      self.removeLoading?.('loadSocialReferent')
    }
  }),
  saveSocialReferent: flow(function* saveSocialReferent(data) {
    self.setLoading?.('saveSocialReferent')

    try {
      const socialReferent = yield getRoot(self).SocialReferentStore.patch(
        { id: self.socialReferent.id, ...data },
        { customId: `${self.socialReferent.id}` },
      )
      if (socialReferent) self.socialReferent = socialReferent
    } finally {
      self.removeLoading?.('saveSocialReferent')
    }
  }),
  unsubscribeSocialReferent: flow(function* unsubscribeSocialReferent() {
    self.setLoading?.('unsubscribeSocialReferent')
    try {
      const socialReferent = yield getRoot(self).SocialReferentStore.patch(
        {
          ...self.socialReferent,
          employeeState: EMPLOYEE_STATE.INACTIVE,
        },
        { customId: `${self.socialReferent.id}` },
      )
      if (socialReferent) self.socialReferent = socialReferent
    } finally {
      self.removeLoading?.('unsubscribeSocialReferent')
    }
  }),
  createSr: flow(function* createSr(data) {
    self.setLoading?.('createSr')
    try {
      return yield getRoot(self).SocialReferentStore.post(data)
    } finally {
      self.removeLoading?.('createSr')
    }
  }),
  loadUserInfo: flow(function* loadUserInfo() {
    self.setLoading?.('loadUserInfo')
    try {
      if (isRoot(self)) return
      const user = yield getRoot(self).SocialReferentStore.fetch('userinfo')

      if (user) {
        self.user = user
      }
    } finally {
      self.removeLoading?.('loadUserInfo')
    }
  }),
  loadTestResults: flow(function* loadTestResults() {
    self.setLoading?.('loadTestResults')
    try {
      const testResults = yield getRoot(self).TestResultStore.get(self.policyId)

      if (testResults) self.testResults = testResults
    } finally {
      self.removeLoading?.('loadTestResults')
    }
  }),
  loadAppointments: flow(function* loadAppointments({
    startDate,
    endDate,
    id = null,
    policyId,
  }) {
    if (isRoot(self)) return
    self.setLoading?.('loadAppointments')
    try {
      const appointments = yield getRoot(self).AppointmentStore.fetchAll({
        include: 'policy',
        noClear: true,
        filter: {
          Appointment: {
            startDate: {
              value: convertDateToUtc(startDate).format(),
            },
            endDate: {
              value: convertDateToUtc(endDate).format(),
            },
            ...(id && { socialReferentId: { value: id } }),
            ...(policyId && { policyId: { value: policyId } }),
          },
        },
      })
      if (appointments) {
        self.appointments = null
        self.appointments = appointments
      }
    } finally {
      self.removeLoading?.('loadAppointments')
    }
  }),
  loadUnavailableSlots: flow(
    function* loadUnavailableSlots(startDate, endDate, id) {
      if (isRoot(self)) return
      self.setLoading?.('loadUnavailableSlots')
      try {
        const unavailableSlots = yield getRoot(
          self,
        ).UnavailableSlotStore.fetchAll({
          noClear: true,
          filter: {
            Appointment: {
              startDate: {
                value: convertDateToUtc(startDate).format(),
              },
              endDate: {
                value: convertDateToUtc(endDate).format(),
              },
              ...(id && { socialReferentId: { value: id } }),
            },
          },
        })
        if (unavailableSlots) {
          self.unavailableSlots = null
          self.unavailableSlots = unavailableSlots
        }
      } finally {
        self.removeLoading?.('loadUnavailableSlots')
      }
    },
  ),
  formatUnavailableSlots(unavailableSlots = []) {
    return unavailableSlots?.map((slot) => {
      return { ...slot, appointmentType: APPOINTMENT_TYPES.ABSENCES }
    }, [])
  },
  updateSubState: flow(function* updateSubState(subState) {
    self.setLoading?.('updateSubState')
    // As we have the "none" option in the dropdown, and subState is of type number, the subState can't be "none"
    const isNoneOrSame =
      subState === 'none' || Number(self.policy?.subState) === Number(subState)

    if (!self.policy || isNoneOrSame) return

    try {
      const customUrl = `policies/${self.policyId}/change_sub_state`
      yield self.savePolicy(
        { subState: Number(subState) },
        { customUrl, withoutId: true },
      )
    } finally {
      self.removeLoading?.('updateSubState')
    }
  }),
  loadSchedule: flow(function* loadSchedule() {
    if (isRoot(self)) return
    self.setLoading?.('loadSchedule')
    try {
      const schedules = yield getRoot(self).AvailableSlotStore.fetchAll({
        customUrl: `policies/${self.policyId}/coordinator_team_available_slots`,
        params: {
          startDate: self.startDate,
          noDigital: self.noDigital,
        },
      })
      if (schedules) {
        self.schedules = null
        self.schedules = schedules
      }
    } finally {
      self.removeLoading?.('loadSchedule')
    }
  }),
  setScheduleFilters: flow(function* setFilters({ date, noDigital }) {
    self.setLoading?.('setScheduleFilters')
    try {
      self.startDate = date
      self.noDigital = noDigital
      yield self.loadSchedule()
    } finally {
      self.removeLoading?.('setScheduleFilters')
    }
  }),
  loadPhoneCalls: flow(function* loadPhoneCalls() {
    self.setLoading?.('loadPhoneCalls')
    try {
      const phoneCalls = yield getRoot(self).PhoneCallStore.fetchAll({
        customUrl: `/policies/${self.policyId}/phone_calls`,
      })

      if (phoneCalls) {
        self.phoneCalls = phoneCalls
      }
    } finally {
      self.removeLoading?.('loadPhoneCalls')
    }
  }),
  loadCommunications: flow(function* loadCommunications() {
    self.setLoading?.('loadCommunications')
    try {
      const communications = yield getRoot(self).PolicyRemainderStore.fetchAll({
        filter: {
          Policy: {
            ...(self.policyId && { policyId: { value: self.policyId } }),
          },
        },
      })

      if (communications) {
        self.communications = communications
      }
    } finally {
      self.removeLoading?.('loadCommunications')
    }
  }),
  loadAgenda: flow(function* loadAgenda() {
    self.setLoading?.('loadAgenda')
    try {
      const appointments = yield getRoot(self).AppointmentStore.fetchAll({
        include: 'policy',
        noClear: true,
        filter: {
          Appointment: {
            ...(self.policyId && { policyId: { value: self.policyId } }),
          },
        },
      })

      if (appointments) {
        self.appointments = appointments
      }
    } finally {
      self.removeLoading?.('loadAgenda')
    }
  }),
  confirmCommunication: flow(function* confirmCommunication(id) {
    self.setLoading?.('confirmCommunication')
    try {
      yield getRoot(self).PolicyRemainderStore.fetch(null, {
        withoutId: true,
        customUrl: `/policy_remainders/${id}/confirm`,
        avoidUpdate: true,
      })
      yield self.loadCommunications()
    } finally {
      self.removeLoading?.('confirmCommunication')
    }
  }),
  checkIfCanGenerateRoom: flow(function* checkIfCanGenerateRoom(id) {
    self.setLoading?.('checkIfCanGenerateRoom')
    try {
      const canGenerateRoom = yield getRoot(
        self,
      ).AppointmentGenerateRoomStore.fetch(null, {
        withoutId: true,
        customUrl: `/appointments/${id}/can_regenerate_room/`,
      })

      if (canGenerateRoom) {
        self.canGenerateRoom = canGenerateRoom
      }
    } finally {
      self.removeLoading?.('checkIfCanGenerateRoom')
    }
  }),
  generateRoom: flow(function* generateRoom(id) {
    self.setLoading?.('generateRoom')
    try {
      const generatedRoom = yield getRoot(
        self,
      ).AppointmentGenerateRoomStore.fetch(null, {
        withoutId: true,
        customUrl: `/appointments/${id}/regenerate_room/`,
      })
      if (generatedRoom) {
        self.generatedRoom = generatedRoom
      }
    } finally {
      self.removeLoading?.('generateRoom')
    }
  }),
  getAllInitialTest: flow(function* getAllInitialTest() {
    self.setLoading?.('getAllInitialTest')
    try {
      if (isRoot(self)) return
      const tests = yield getRoot(self).InitialAnalysisStore.fetchAll({
        customUrl: `policies/${self.policyId}/initial_analysis/`,
      })
      if (tests?.length) {
        self.setAllTest(tests)
      } else {
        yield self.createInitialTest()
      }
    } finally {
      self.removeLoading?.('getAllInitialTest')
    }
  }),
  createInitialTest: flow(function* createInitialTest() {
    self.setLoading?.('createInitialTest')
    try {
      const tests = yield getRoot(self).InitialAnalysisStore.create(
        { id: self.policyId },
        {
          customUrl: `policies/${self.policyId}/initial_analysis`,
        },
      )
      if (tests?.length) {
        self.getAllInitialTest()
      }
    } finally {
      self.removeLoading?.('createInitialTest')
    }
  }),
  setAllTest: (tests) => {
    tests.forEach((test) => {
      self[INITIAL_REPORT_TEST_NAME[test.testType]] = test
    })
  },
  getAllInitialTestResults: flow(function* getAllInitialTestResults(policyId) {
    self.setLoading?.('getAllInitialTestResults')
    try {
      const tests = yield getRoot(self).InitialAnalysisResultsStore.fetchAll({
        customUrl: `policies/${policyId}/initial_analysis_result/`,
      })

      if (tests?.length) {
        self.initialTestList = tests
      }
    } catch (error) {
      self.initialTestList = null
    } finally {
      self.removeLoading?.('getAllInitialTestResults')
    }
  }),
  getTwilioRoomSeed: flow(function* getTwilioRoomSeed() {
    self.setLoading?.('getTwilioRoomSeed')
    try {
      const response = yield getEnv(self).client.get(
        `policies/${self.policyId}/join_conversation`,
      )
      if (response) {
        self.roomSid = response.data.data.roomSid
      }
    } catch (error) {
      self.roomSid = null
    } finally {
      self.removeLoading?.('getTwilioRoomSeed')
    }
  }),
  saveAppointment: flow(function* saveAppointment(data) {
    self.setLoading?.('saveAppointment')
    try {
      const selectedAppointment = yield getRoot(self).AppointmentStore.get(
        data.id,
      )

      if (selectedAppointment) {
        self.selectedAppointment = selectedAppointment
        self.selectedAppointment.update(data)
        yield self.selectedAppointment.save()
      }
    } finally {
      self.removeLoading?.('saveAppointment')
    }
  }),
  createAppointment: flow(function* createAppointment(data) {
    self.setLoading?.('createAppointment')
    try {
      yield getRoot(self).AppointmentStore.create(data)
    } finally {
      self.removeLoading?.('createAppointment')
    }
  }),
  deleteAppointment: flow(function* deleteAppointment(id) {
    self.setLoading?.('deleteAppointment')
    try {
      yield getRoot(self).AppointmentStore.delete(id)
    } finally {
      self.removeLoading?.('deleteAppointment')
    }
  }),
  loadAvailableSrForChangeCampaign: flow(
    function* loadAvailableSrForChangeCampaign() {
      self.setLoading?.('loadAvailableSrForChangeCampaign')
      try {
        const availableSr = yield getRoot(self).AvailableSRStore.fetchAll({
          customUrl: `person_services/${self.firstServiceId}/get_available_sr_for_change`,
        })

        self.availableSocialReferentList = availableSr
      } finally {
        self.removeLoading?.('loadAvailableSrForChangeCampaign')
      }
    },
  ),
  changeOp: flow(function* changeOp({ socialReferent: socialReferentId }) {
    self.setLoading?.('changeOp')
    try {
      const availableSr = yield getRoot(self).AvailableSRStore.post(
        { socialReferentId },
        {
          customUrl: `person_services/${self.firstServiceId}/change_service_sr`,
        },
      )

      self.availableSocialReferentList = availableSr
    } finally {
      self.removeLoading?.('changeOp')
    }
  }),
  loadAvailableSrForChangeCare: flow(function* loadAvailableSrForChangeCare() {
    self.setLoading?.('loadAvailableSrForChangeCare')
    try {
      const availableSr = yield getRoot(self).AvailableSRStore.fetchAll({
        customUrl: `policies/${self.policyId}/get_available_sr_for_change`,
      })

      self.availableSocialReferentList = availableSr
    } finally {
      self.removeLoading?.('loadAvailableSrForChangeCare')
    }
  }),
  changeOpCare: flow(function* changeOpCare({
    socialReferent: socialReferentId,
  }) {
    self.setLoading?.('changeOpCare')
    try {
      const availableSr = yield getRoot(self).AvailableSRStore.post(
        { socialReferentId },
        {
          customUrl: `policies/${self.policyId}/change_policy_sr`,
        },
      )

      self.availableSocialReferentList = availableSr
    } finally {
      self.removeLoading?.('changeOpCare')
    }
  }),
  loadPaginatedPolicies: flow(function* loadPaginatedPolicies({
    isClear = false,
    socialReferentId = null,
    pageSize = 30,
    policyType = null,
  }) {
    if (isRoot(self)) return
    self.setLoading?.('loadPaginatedPolicies')
    try {
      const policies = yield getRoot(self).PolicyStore.fetchPage(
        self.page + 1,
        {
          search: self.search || '',
          pageSize,
          filter: {
            Policy: {
              socialReferent: {
                value: socialReferentId,
              },
              policyType: {
                value: policyType,
              },
            },
          },
        },
      )

      if (policies && policies.items) {
        self.page = policies.pagination?.page || 0
        self.pages = policies.pagination?.pages || 0
        self.count = policies.pagination?.count || 0
        if (self.policies && !isClear) {
          return [...self.policies, ...policies.items]
        } else {
          return policies.items
        }
      }
    } finally {
      self.removeLoading?.('loadPaginatedPolicies')
    }
  }),
  loadPaginatedAndFilteredPolicies: flow(
    function* loadPaginatedAndFilteredPolicies({
      isClear = false,
      sort = null,
      filters = null,
    }) {
      if (isRoot(self)) return
      self.setLoading?.('loadPaginatedAndFilteredPolicies')
      try {
        const policies = yield getRoot(self).PolicyStore.fetchPage(
          self.page + 1,
          {
            search: self.search || '',
            pageSize: self.pageSize,
            include: 'social_referent,subscription_office',
            params: {
              sort,
            },
            filter: {
              Policy: {
                socialReferent: {
                  value: filters?.socialReferent || null,
                },
                subscriptionAppointment: {
                  value: filters?.subscriptionAppointment?.value || null,
                },
                initialAnalysisAppointment: {
                  value: filters?.initialAnalysisAppointment?.value || null,
                },
              },
            },
          },
        )

        if (policies && policies.items) {
          self.total = policies.pagination?.count || 0
          if (self.policies && !isClear) {
            return [...self.policies, ...policies.items]
          } else {
            return policies.items
          }
        }
      } finally {
        self.removeLoading?.('loadPaginatedAndFilteredPolicies')
      }
    },
  ),
  loadPaginatedAndFilteredSocialReferents: flow(
    function* loadPaginatedAndFilteredSocialReferents({
      isClear = false,
      sort = null,
      filters = null,
    }) {
      if (isRoot(self)) return
      self.setLoading?.('loadPaginatedAndFilteredSocialReferents')
      try {
        const socialReferent = yield getRoot(
          self,
        ).SocialReferentStore.fetchPage(self.page + 1, {
          search: self.search || '',
          pageSize: self.pageSize,
          params: {
            sort,
          },
          filter: {
            SocialReferent: {},
          },
        })

        if (socialReferent && socialReferent.items) {
          self.total = socialReferent.pagination?.count || 0
          if (self.socialReferent && !isClear) {
            return [...self.socialReferent, ...socialReferent.items]
          } else {
            return socialReferent.items
          }
        }
      } finally {
        self.removeLoading?.('loadPaginatedAndFilteredSocialReferents')
      }
    },
  ),
  getConversations: flow(function* getConversations() {
    self.setLoading('getConversations')
    if (isRoot(self)) return
    try {
      const response = yield getRoot(
        self,
      ).ConversationParticipantsStore.fetchAll({
        include: 'conversation',
        noClear: true,
        throwError: true,
      })

      if (response) {
        self.conversationParticipants = response
      }
    } finally {
      self.removeLoading('getConversations')
    }
  }),
})
