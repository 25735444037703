import { BUTTON_SIZES, Button } from '@shared/ui'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import {
  ActionsBlock,
  ActionsBlockContentTwoCols,
  ActionsBlockTitle,
} from '../../../styles'

type EditProfileBlockProps = {
  isEditing: boolean
  onStartEditing: () => void
  onSubmit: () => void
}

export const EditProfileBlock: FC<EditProfileBlockProps> = ({
  isEditing = false,
  onStartEditing,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const TRANSLATION_BASE_KEY = 'userArea.profile.actions.editProfile'

  const { isLoading } = useLayoutContext()

  return (
    <ActionsBlock>
      <ActionsBlockTitle>
        {t(`${TRANSLATION_BASE_KEY}.title`)}
      </ActionsBlockTitle>
      <ActionsBlockContentTwoCols>
        {isEditing ? (
          <Button
            onClick={onSubmit}
            size={BUTTON_SIZES.small}
            disabled={isLoading}
          >
            {t('common:save')}
          </Button>
        ) : (
          <Button
            onClick={onStartEditing}
            size={BUTTON_SIZES.small}
            disabled={isLoading}
          >
            {t(`${TRANSLATION_BASE_KEY}.button`)}
          </Button>
        )}
      </ActionsBlockContentTwoCols>
    </ActionsBlock>
  )
}
