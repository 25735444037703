export const dateBannerContainerClasses = `
    p-6
    text-center
    text-dark-gray
`

export const dateBannerTitleClasses = `
    text-sm
`

export const dateBannerDateClasses = `
    capitalize
    text-2xl
    font-semibold
`
