import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { SRCheckbox } from '@sr/ui'
import { FORM_NAME } from '../../enums'

import { setLocalStorageItem, getLocalStorageItem } from '@shared/utils'
import { FORM_TYPE, Input, Label } from '@shared/ui'

import { ScheduleTitle } from '../styles'
import { CheckboxContainer, inputClasses, ScheduleNoDigital } from './styles'

export const ScheduleNoDigitalCheckbox = ({ name, isMod, setModifyDate }) => {
  const { t } = useTranslation()
  const dateSelectedLocal = getLocalStorageItem('dateSelected')

  useEffect(() => {
    if (dateSelectedLocal === null) {
      setLocalStorageItem("dateSelected", null)
    }
  }, [dateSelectedLocal])

  const handleChange = () => {
    if (isMod) {
      setModifyDate(null)
    }
  }

  return (
    <ScheduleNoDigital>
      <ScheduleTitle>{t('schedule.noDigital.title')}</ScheduleTitle>
      {t('schedule.noDigital.text')}
      <CheckboxContainer>
        <Input
          type={FORM_TYPE.checkbox}
          name={name}
          className={`${inputClasses} accent-[#004039]`}
          onChange={handleChange}
        />
        <Label>{t('schedule.noDigital.checkbox')}</Label>
      </CheckboxContainer>
      <SRCheckbox
        name={FORM_NAME.originQida}
        label={t('schedule.noDigital.originLabel')}
        className={`accent-[#004039]`}
      />
    </ScheduleNoDigital>
  )
}
