import { getLocalStorageItem } from '@shared/utils'
import { isMobile, removeUndefineds } from './utils'

// Connect options are a configuration for the room and all participants.

export default function useConnectionOptions() {
  // See: https://sdk.twilio.com/js/video/releases/2.0.0/docs/global.html#ConnectOptions
  // for available connection options.
  const connectionOptions = {
    // Bandwidth Profile, Dominant Speaker, and Network Quality
    // features are only available in Small Group or Group Rooms.
    // Please set "Room Type" to "Group" or "Small Group" in your
    // Twilio Console: https://www.twilio.com/console/video/configure
    bandwidthProfile: {
      video: {
        mode: 'collaboration',
        dominantSpeakerPriority: 'standard',
        trackSwitchOffMode: undefined,
        contentPreferencesMode: 'auto',
        clientTrackSwitchOffControl: 'auto',
      },
    },
    // Discomment this line if we have dominant speaker
    // dominantSpeaker: true,
    networkQuality: { 
      local: parseInt(getLocalStorageItem('twilioNetworkQualityLocal')) || 1, 
      remote: parseInt(getLocalStorageItem('twilioNetworkQualityRemote')) || 1 
    },

    // Comment this line if you are playing music.
    maxAudioBitrate: getLocalStorageItem('twilioMaxAudioBitrate') || '16000',

    preferredVideoCodecs: 'auto',
    chromeSpecificConstraints: {
      mandatory: {
        // Enable CPU overuse detection
        googCpuOveruseDetection: true,
        // Not sure about this, but Google Hangouts uses it
        googCpuOveruseEncodeUsage: true,
        // The CPU % threshold for overuse
        googCpuOveruseThreshold: 85,
      },
    },
  }

  // For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
  if (isMobile && connectionOptions?.bandwidthProfile?.video) {
    connectionOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000
  }

  // Here we remove any 'undefined' values. The twilio-video SDK will only use defaults
  // when no value is passed for an option. It will throw an error when 'undefined' is passed.
  return removeUndefineds(connectionOptions)
}
