import tw from 'tailwind-styled-components'

export const ConfirmationModalContainer = tw.div`
    flex
    flex-col
    justify-center
    items-center
    gap-4
    mb-4
    text-primary
`

export const ConfirmationModalTitle = tw.div`
    font-semibold
`

export const ConfirmationModalSR = tw.div`
    text-center
`

export const ConfirmationModalAppointment = tw.div`
    flex
    justify-center
    items-center
    gap-2
`
