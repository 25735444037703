import { useEffect, useState } from 'react'
import { CarouselDots } from '../carousel-dots'
import { Carousel } from '../carousel/carousel'
import { DateBanner } from '../date-banner'
import { Schedule } from '../schedule'
import { getStartDateWeek1, getStartDateWeek2 } from './utils'

export const CarouselAgenda = ({
  noDigital,
  schedules,
  dateSelected,
  fieldName,
  dateBannerClasses,
  isMod,
  modifyDate,
  setModifyDate,
}) => {

  const [startDateWeek1, setStartDateWeek1] = useState(null)
  const [startDateWeek2, setStartDateWeek2] = useState(null)
  const [date, setDate] = useState(null)

  useEffect(() => {
    setStartDateWeek1(getStartDateWeek1({ noDigital }))
    setStartDateWeek2(getStartDateWeek2({ noDigital }))
  }, [noDigital])


  useEffect(() => {
    const newDate = isMod ? modifyDate : dateSelected
    setDate(newDate)
  }, [isMod, modifyDate, dateSelected])

  return (
      <Carousel DotsComponent={CarouselDots}>
        {startDateWeek1 && (
          <Schedule
            noDigital={noDigital}
            startDate={startDateWeek1}
            schedules={schedules}
            fieldName={fieldName}
            watchValue={dateSelected}
            isMod={isMod}
            modifyDate={modifyDate}
            setModifyDate={setModifyDate}
          />
        )}
        {startDateWeek2 && (
          <Schedule
            noDigital={noDigital}
            startDate={startDateWeek2}
            schedules={schedules}
            fieldName={fieldName}
            watchValue={dateSelected}
            isMod={isMod}
            modifyDate={modifyDate}
            setModifyDate={setModifyDate}
          />
        )}
      </Carousel>
  )
}

