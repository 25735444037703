import { FC } from 'react'
import {
  UserInfoBoxContainer,
  UserInfoBoxLabel,
  TextareaContainer,
  UserInfoValue,
} from '../styles'
import { UserInfoItem } from '../user-info'
import { SRInput, SRSelect } from '../../../sr-form-elements'
import { useTranslation } from 'react-i18next'
import { EditableType } from '../enums'

interface UserInfoBoxProps extends UserInfoItem {
  isEditing?: boolean
  hasToGrow?: boolean
}

export const UserInfoBox: FC<UserInfoBoxProps> = ({
  name = '',
  label,
  value,
  isEditing = false,
  hasToGrow = false,
  editableType = EditableType.input,
  selectOptions = [],
  selectValue,
  formattedValue,
  isTextarea,
}) => {
  const { t } = useTranslation()

  const EditableComponent = () =>
    ({
      [EditableType.date]: (
        <SRInput
          name={name || label}
          defaultValue={value}
          placeholder={value}
          type="date"
        />
      ),
      [EditableType.input]: (
        <SRInput
          name={name || label}
          defaultValue={value}
          placeholder={value}
        />
      ),
      [EditableType.select]: (
        <SRSelect
          name={name}
          options={selectOptions}
          defaultValue={selectValue}
          placeholder={label}
        />
      ),
    })[editableType]

  return (
    <UserInfoBoxContainer
      className={hasToGrow ? 'md:col-span-2 lg:col-span-3 xl:col-span-4' : ''}
    >
      <UserInfoBoxLabel>{label}</UserInfoBoxLabel>

      {isEditing && <EditableComponent />}
      {!isEditing &&
        (isTextarea ? (
          <TextareaContainer>{value}</TextareaContainer>
        ) : (
          <UserInfoValue>
            {formattedValue || value || t('common:notIndicated')}
          </UserInfoValue>
        ))}
    </UserInfoBoxContainer>
  )
}
