import { FC, ReactNode } from 'react'
import { UserInfo } from '@sr/ui'
import { Divider, Form } from '@shared/ui'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { EditProfileBlock } from './components'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useSchema } from './schema'

type FormProps = {
  [x: string]: unknown
}

type GenericProfileProps = {
  personalData: []
  isEditing: boolean
  setEditingMode: (mode: boolean) => void
  onSaveData: (form: FormProps) => void
  ActionsComponent?: ReactNode
  defaultValues?: any
}

export const GenericProfile: FC<GenericProfileProps> = ({
  personalData,
  isEditing,
  setEditingMode,
  onSaveData,
  ActionsComponent,
  defaultValues = {},
}) => {
  const { t } = useTranslation()

  const onStartEditing = () => setEditingMode(true)

  const schema = useSchema(t)
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const {
    handleSubmit,
    formState: { dirtyFields },
  } = methods

  const onSubmit = handleSubmit((form) => {
    const modifiedFormData = Object.fromEntries(
      Object.entries(form).filter(([key]) => dirtyFields[key]),
    )

    onSaveData(modifiedFormData)
    setEditingMode(false)
  })

  return (
    <div className="generic-profile">
      <Form methods={methods} className="generic-profile__user-info">
        <div className="generic-profile__title">
          {t('userArea.profile.personalData.title')}
        </div>
        <UserInfo data={personalData} />
      </Form>

      <Divider vertical />

      <div className="generic-profile__user-actions">
        <EditProfileBlock
          isEditing={isEditing}
          onStartEditing={onStartEditing}
          onSubmit={onSubmit}
        />
        {ActionsComponent}
      </div>
    </div>
  )
}
