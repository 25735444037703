import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { useFormContext } from 'react-hook-form'

import { compareDates, getDateFormatted, setLocalStorageItem, getLocalStorageItem } from '@shared/utils'

import { ScheduleRadioButton } from './schedule-radio-button'
import { CLIENT_FORM } from '@shared/data-access'

export const ScheduleHour = observer(
  ({
    item,
    className,
    disabled,
    fieldName,
    testId = 'ScheduleHourLabel',
    watchValue,
    noDigital,
    modifyDate,
    setModifyDate,
    isMod,
  }) => {

    const startHourFormatted = getDateFormatted(item.slotStart, 'HH:mm')
    const endHourFormatted = getDateFormatted(item.slotEnd, 'HH:mm')
    const dateFormatted = getDateFormatted(item.slotStart, '')

    const { watch } = useFormContext()
    const watchValueDate = watch(CLIENT_FORM.firstAppointmentDate) || dateFormatted 
    const [isSelected, setIsSelected] = useState(false)

    const disabledClasses = disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
    const heightClasses = noDigital ? 'h-28' : 'h-8'
    const checkClasses = isSelected ? 'bg-primary text-white font-bold' : 'bg-vcx-dark-white'

    const handleClick = () => {
      setLocalStorageItem('dateSelected', watchValueDate)
      setLocalStorageItem('clicked', true)
      setModifyDate(watchValueDate)
      setIsSelected(compareDates(item.slotStart, watchValueDate))
    }

    useEffect(() => {
      // pintar si está seleccionado en un inicio o no
      if(item.isAvailable) {
        setIsSelected(compareDates(item.slotStart, watchValue))
        setModifyDate(watchValue)
      }
    }, [ watchValue, item.slotStart, item.available])

    return (
      <button
        type="button"
        className={`${checkClasses} ${heightClasses} ${className} ${disabledClasses}`}
        data-testid={testId}
        onClick={handleClick}
        disabled={disabled}
      >
        {`${startHourFormatted} - ${endHourFormatted}`}
        <ScheduleRadioButton
          id={dateFormatted}
          className="opacity-0 fixed w-0"
          name={fieldName}
          value={dateFormatted}
          disabled={disabled}
        >
          {`${startHourFormatted} - ${endHourFormatted}`}
        </ScheduleRadioButton>
      </button>
    )
  }
)
