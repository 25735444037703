import { FC, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import {
  AppointmentBlock,
  ChangeOpBlock,
  ReminderBlock,
  ServicesBlock,
  UploadFileBlock,
} from '../../blocks'
import { useRouteState } from '@shared/utils'
import { ProfileCareActionsRouteState } from './state'
import { useNavigate, useParams } from 'react-router-dom'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'
import { useTranslation } from 'react-i18next'
import { Form } from '@shared/ui'
import { useForm } from 'react-hook-form'
import { rejectOptions } from './enums'
import { ModalType, useAnalysisModal } from '@sr/routes/common/analysis'

type CareActionsProps = {
  reload: () => void
}

export const CareActions: FC<CareActionsProps> = observer(({ reload }) => {
  const { t } = useTranslation()
  const { id: policyId } = useParams()
  const navigate = useNavigate()
  const {
    isLoading,
    policy,
    user,
    sendSignUpCommunication,
    uploadVideo,
    appointment,
    updateSubState,
    canChangeOp,
    availableSocialReferentListParsed,
    loadAvailableSrForChangeCare,
    modalType,
    setModal,
    changeOpCare,
    showSendEmailButton,
    canUploadFile,
    isModifyAppointment,
    isSubscriptionPending,
  } = useRouteState(ProfileCareActionsRouteState, {
    policyId,
  })

  const { setIsLoading } = useLayoutContext()
  const sendEmailTranslation = policy?.canResendEmail ? 'resend' : 'send'

  const showAppointment = !user?.isCoordinator && appointment

  const onSchedule = () => {
    navigate(`${ROUTE_PATH.userArea(policyId)}/${ROUTE_PATH.schedule}`)
  }

  const goToVideoCall = () => {
    window.location.replace(appointment.meetingUrl)
  }

  const methodsSubState = useForm()
  const { handleSubmit: handleSubmitSubState } = methodsSubState

  const onRejection = handleSubmitSubState((form) => {
    const { subState } = form

    updateSubState(subState)
  })

  const methodsChangeOp = useForm()
  const { handleSubmit: handleSubmitChangeOp } = methodsChangeOp

  const onChangeOp = handleSubmitChangeOp(async (form) => {
    await changeOpCare(form)
    reload()
    await loadAvailableSrForChangeCare()
    setModal(ModalType.success)
  })

  const openModal = () => {
    setModal(ModalType.confirmation)
  }
  const onClose = () => {
    setModal(null)
  }

  const Modal = useAnalysisModal({
    modalType,
    reload,
    onChangeOp,
    onClose,
    fullName: policy?.fullName,
    onConfirmText: 'common:save',
    confirmModalTextMain:
      'campaign.userArea.profile.changeOpModalConfirmationMain',
    confirmModalTextSecond:
      'campaign.userArea.profile.changeOpModalConfirmationSecond',
    successModalTextMain: 'campaign.userArea.profile.changeOpModalSuccessMain',
    successModalTextSecond: '',
    noRoute: true,
  })

  useEffect(() => {
    setIsLoading(isLoading)
  }, [isLoading])

  const serviceOptions = [
    {
      value: '1',
      name: t('common:testModality.suscription'),
      label: t('common:testModality.suscription'),
    },
  ]

  return (
    <>
      {isSubscriptionPending && (
        <AppointmentBlock
          action={onSchedule}
          showAppointment={isModifyAppointment}
          appointment={{ startDate: policy?.firstAppointmentDate }}
        />
      )}
      <Form methods={methodsSubState}>
        {isSubscriptionPending && (
          <ServicesBlock
            project={t('projects.care')}
            onRejection={onRejection}
            onAccept={onSchedule}
            serviceOptions={serviceOptions}
            rejectOptions={rejectOptions(t)}
            disabled={isLoading}
          />
        )}
      </Form>
      {showAppointment && (
        <AppointmentBlock
          action={goToVideoCall}
          actionText={t('userArea.profile.actions.appointment.videocallButton')}
          showAppointment
          appointment={appointment}
        />
      )}
      {showSendEmailButton && (
        <ReminderBlock
          sendEmail={sendSignUpCommunication}
          sendEmailTranslation={sendEmailTranslation}
        />
      )}
      {canUploadFile && <UploadFileBlock onDrop={uploadVideo} />}
      {canChangeOp && (
        <Form methods={methodsChangeOp}>
          <ChangeOpBlock
            srList={availableSocialReferentListParsed}
            action={openModal}
          />
        </Form>
      )}
      {modalType && Modal}
    </>
  )
})
