export const scheduleAgendaContainerClasses = `
    grid
    grid-cols-7
    gap-2
    xl:gap-6
`

export const scheduleAgendaDayContainerClasses = `
    flex
    flex-col
`

export const scheduleAgendaHourContainerClasses = `
    flex
    flex-col
    gap-2
`

export const scheduleAgendaDayClasses = `
    flex
    place-content-center
`

export const scheduleAgendaHourClasses = ` 
    inline-flex 
    justify-center 
    items-center 
    border rounded 
    w-full
    text-sm
`

export const scheduleAgendaCheckedClasses = `
    bg-primary 
    text-white 
    font-bold
`

export const scheduleAgendaUncheckedClasses = `
    bg-vcx-dark-white
`

export const dayInnerContainerClasses = `
    w-full
    flex
    gap-2
    justify-center
    items-end
    uppercase
    font-semibold
    pb-4
`

export const dayNumberClasses = `
    font-bold 
    text-2xl
`
