export const inputClassName = `
    bg-white
    border
    border-solid
    border-gray
    w-full
    py-2.5
    px-3
    rounded
    text-zinc-500
    disabled:opacity-75
    disabled:cursor-not-allowed
`

export const timeInputContainerClassName = `
    flex
    justify-stretch
    gap-6
`

export const selectErrorClassName = `
    border-error
    bg-error-10
    focus-visible:outline-error
`

export const errorSpanClassName = `
    flex
    items-center
    text-error
    text-sm
    pt-2
`
export const textareaClassName = `
    bg-white
    border
    border-solid
    border-gray
    w-full
    py-2.5
    px-3
    rounded
    text-zinc-500
    disabled:opacity-75
    disabled:cursor-not-allowed
`
