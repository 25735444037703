import {
  getWorkDays,
  getDayWithExactHour,
  compareDates,
  parseToSpanishZone,
} from '@shared/utils'

import {
  scheduleAgendaContainerClasses,
  scheduleAgendaDayContainerClasses,
  scheduleAgendaHourContainerClasses,
  scheduleAgendaDayClasses,
  scheduleAgendaHourClasses,
  dayInnerContainerClasses,
  dayNumberClasses,
} from './styles'

import { ScheduleDay } from './components/schedule-day'
import { ScheduleHour } from './components/schedule-hour'
import { SCHEDULE_FACE_TO_FACE, SCHEDULE_HOURS } from './enum'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

export const Schedule = observer(
  ({
    schedules,
    noDigital,
    startDate,
    watchValue,
    fieldName,
    containerClassName = '',
    dayContainerClassName = '',
    dayClassName = '',
    hourContainerClassName = '',
    hourClassName = '',
    dayInnerContainerClassName = '',
    dayNumberClassName = '',
    modifyDate,
    setModifyDate = () => {},
    isMod
  }) => {

    const [dates, setDates] = useState([])

    const checkUnavailable = (dayWithExactHour) => {
      return schedules.some((appointment) => {
        return compareDates(
          dayWithExactHour,
          parseToSpanishZone(appointment.slotDate),
        )
      })
    }

    const mapHours = (date) => {
      const schedulesHours = noDigital ? SCHEDULE_FACE_TO_FACE : SCHEDULE_HOURS
      return schedulesHours.map((hours) => {
        const startDayWithExactStartHour = getDayWithExactHour(date, {
          hour: hours.startHour,
          minute: hours.startMinute,
        })
        const startDayWithExactEndHour = getDayWithExactHour(date, {
          hour: hours.endHour,
          minute: hours.endMinute,
        })

        return {
          slotStart: startDayWithExactStartHour,
          slotEnd: startDayWithExactEndHour,
          isAvailable: checkUnavailable(startDayWithExactStartHour),
          testId: `ScheduleHourLabel-${date}`
        }
          
      }, [])
    }

    const getDates = () => {      
      return getWorkDays(startDate).map(
        (date) => ({
          day: date,
          dayWithExactHours: mapHours(date),
        }),
        [],
      )
    }

    useEffect(() => {
      setDates(getDates())
    }, [startDate, schedules])

    return (
      <div
        className={`${scheduleAgendaContainerClasses} ${containerClassName}`}
      >
        {dates.map((date, index) => {
          return (
            <div
              key={`${date.day}.${index}`}
              className={`${scheduleAgendaDayContainerClasses} ${dayContainerClassName}`}
              data-testid={`schedule-agenda-day-${index}`}
            >
              <ScheduleDay
                dayClassName={`${scheduleAgendaDayClasses} ${dayClassName}`}
                containerClassName={`${dayInnerContainerClasses} ${dayInnerContainerClassName}`}
                dayNumberClassName={`${dayNumberClasses} ${dayNumberClassName}`}
                day={date.day}
              />
              <div
                className={`${scheduleAgendaHourContainerClasses} ${hourContainerClassName}`}
              >
                {date.dayWithExactHours?.map((item, indexHour) => {
                  return (
                    <ScheduleHour
                      noDigital={noDigital}
                      watchValue={watchValue}
                      className={`${scheduleAgendaHourClasses} ${hourClassName}`}
                      disabled={!item.isAvailable}
                      fieldName={fieldName}
                      key={`${item.dayWithExactHour}.${indexHour}`}
                      item={item}
                      modifyDate={modifyDate}
                      setModifyDate={setModifyDate}
                      isMod={isMod}
                      data-testid={item.testId}
                    />
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  },
)
