import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'

import { getDateFormatted, useRouteState, getLocalStorageItem } from '@shared/utils'
import { SRModal } from '@sr/ui'

import { ConfirmationModalRouteState } from './state'

import { Button } from '@shared/ui'

import { ROUTE_PATH } from 'apps/social-referent/src/routes/enums'

import { CheckmarkOutline } from '@carbon/icons-react'
import {
  ConfirmationModalAppointment,
  ConfirmationModalContainer,
  ConfirmationModalSR,
  ConfirmationModalTitle,
} from './styles'


export const ConfirmationModal = observer(({ onClose }) => {
  const { id } = useParams()
  const { t } = useTranslation()
  const { policy } = useRouteState(ConfirmationModalRouteState, {
    policyId: id,
  })
  const navigate = useNavigate()

  if (!policy) return

  const date = getDateFormatted(getLocalStorageItem('dateSelected'), 'DD/MM/YYYY')
  const time = getDateFormatted(getLocalStorageItem('dateSelected'), 'HH:mm')

  const handleContinue = () => {
    navigate(`${ROUTE_PATH.userArea(id)}`)
  }

  return (
    <SRModal onClose={onClose} >
      <ConfirmationModalContainer data-testid='agenda-schedule-modal'>
        <CheckmarkOutline size={36} />
        <ConfirmationModalTitle>
          {t('schedule.modal.confirmation.title')}
        </ConfirmationModalTitle>
        <ConfirmationModalSR>
          {t('schedule.modal.confirmation.userInfo', {
            userName: policy?.fullName,
            srName: policy?.socialReferent?.fullName,
          })}
        </ConfirmationModalSR>
        <ConfirmationModalAppointment>
          <div>{t('schedule.modal.confirmation.date', { date })}</div>
          <div>{t('schedule.modal.confirmation.time', { time })}</div>
        </ConfirmationModalAppointment>
        <Button onClick={handleContinue}>{t('common:continue')}</Button>
      </ConfirmationModalContainer>
    </SRModal>
  )
})
