import { Button } from '@shared/ui'
import { SRScheduleAgenda } from '@sr/ui'
import { useTranslation } from 'react-i18next'

import { DateBanner } from '@shared/ui/components'

import { FORM_NAME } from '../../enums'
import { ScheduleTitle } from '../styles'
import { ScheduleAgendaContainer, dateBannerClasses } from './styles'
import { getLocalStorageItem, setLocalStorageItem } from '@shared/utils'

export const ScheduleAgenda = ({
  noDigital,
  dateSelected,
  firstDateSelected,
  onSubmit,
  schedules,
  buttonText,
  isMod,
  setModifyDate,
  modifyDate,
}) => {

  const { t } = useTranslation()

  if (!schedules) return

  if(dateSelected !== null) {
    setLocalStorageItem('dateSelected', dateSelected)
  }

  return (
    <ScheduleAgendaContainer>
      <ScheduleTitle>{t('schedule.agenda.title')}</ScheduleTitle>
      <SRScheduleAgenda
        noDigital={noDigital}
        fieldName={FORM_NAME.firstAppointmentDate}
        dateSelected={dateSelected || getLocalStorageItem('dateSelected')}
        schedules={schedules}
        isMod={isMod}
        setModifyDate={setModifyDate}
        modifyDate={modifyDate}
      />
      {/* Banner */}
      {(dateSelected || modifyDate || firstDateSelected) && <DateBanner
          dateBannerClasses={dateBannerClasses}
          date={dateSelected || modifyDate}
          appointmentDateWatched={dateSelected}
          noDigital={noDigital}
          isMod={isMod}
        />}
      {/* Submit Button */}
      {(dateSelected || modifyDate || firstDateSelected) && <Button onClick={onSubmit}>{t(buttonText)}</Button>}
    </ScheduleAgendaContainer>
  )
}

