export const userAreaRoute = 'user-area'
export const campaignRoute = 'campaign'

export const ROUTE_PATH = Object.freeze({
  home: '/',
  agenda: (id) => (id ? `/agenda/${id}` : '/'),
  userList: 'user-list',
  userArea: (id) => (id ? `/${userAreaRoute}/${id}` : '/'),
  preSubscriptionControl: 'pre-subscription-control',
  subscriptionControl: 'subscription-control',
  monitoringArea: 'monitoring-area',
  initialReport: 'initial-report',
  preInitialReport: 'pre-initial-report',
  userProfile: 'profile',
  srProfile: (id) => (id ? `/sr-profile/${id}` : '/'),
  srList: 'sr-list',
  login: 'auth/login',
  meet: '/meet',
  meetTokenRoom: ':token/:room',
  meetNotAvailable: 'not-available',
  meetError: 'error/:error',
  error: '/error',
  srPrivateArea: (id) => (id ? `/social-referent-area/${id}` : '/'),
  schedule: 'schedule',
  monitoringChat: 'chat',
  monitoringCommunications: 'communications',
  monitoringRegister: 'register',
  monitoringAgenda: 'agenda',
  monitoringServices: 'services',
  monitoringServicesDetail: 'service-detail/:serviceId',
  newTest: 'new-test',
  userScriptTest: ({ userAreaUrl, testId }) => {
    if (!userAreaUrl || !testId) return '/'
    else return `/${userAreaUrl}/script/${testId}`
  },
  scriptTest: (testId) => (testId ? `script/${testId}` : '/'),
  userAnalysis: ({ userAreaUrl, testId }) => {
    if (!userAreaUrl || !testId) return '/'
    else return `/${userAreaUrl}/analysis/${testId}`
  },
  analysis: (testId) => (testId ? `analysis/${testId}` : '/'),
  appointmentManager: 'appointment-manager',
  portfolioManagementCare: (id) =>
    id ? `/social-referent-area/${id}/portfolio-management-care` : '/',
  portfolioManagementCampaign: (id) =>
    id ? `/social-referent-area/${id}/portfolio-management-campaign` : '/',
  portfolioManagementOp: 'op-selection',
  notificationsChat: 'notifications-chat',
})

export const ROUTE_NAME = Object.freeze({
  [ROUTE_PATH.preSubscriptionControl]: 'userArea.subscription.title',
  [ROUTE_PATH.subscriptionControl]: 'userArea.subscription.title',
  [ROUTE_PATH.userProfile]: 'userArea.profile.title',
  [ROUTE_PATH.monitoringArea]: 'userArea.monitoringArea.title',
  [ROUTE_PATH.preInitialReport]: 'userArea.initialReport.title',
  [ROUTE_PATH.initialReport]: 'userArea.initialReport.title',
  [ROUTE_PATH.srList]: 'srList.title',
  [ROUTE_PATH.schedule]: 'common:scheduleDate',
  [ROUTE_PATH.monitoringAgenda]: 'agenda.title',
  [ROUTE_PATH.monitoringChat]: 'userArea.monitoringArea.title',
  [ROUTE_PATH.monitoringCommunications]: 'userArea.monitoringArea.title',
  [ROUTE_PATH.monitoringRegister]: 'userArea.monitoringArea.title',
  [ROUTE_PATH.monitoringServices]: 'userArea.monitoringArea.title',
  [ROUTE_PATH.monitoringServicesDetail]: 'userArea.monitoringArea.title',
  [ROUTE_PATH.newTest]: 'testList.title',
  analysis: 'common:testModality.generic',
})

export const ROUTE_PATH_CAMPAIGN = Object.freeze({
  userArea: (id) => (id ? `/campaign/${userAreaRoute}/${id}` : '/'),
  userProfile: 'profile',
  userNewTest: 'new-test/',
  userServices: 'services',
  userAgenda: (serviceId) => (serviceId ? `agenda/${serviceId}` : '/'),
  userList: '/campaign/user-list',
  userAnalysis: (testId) => (testId ? `analysis/${testId}` : '/'),
  userScriptTest: (testId) => (testId ? `script/${testId}` : '/'),
  appointmentManager: 'appointment-manager',
})
