import { PolicySubStates } from '@shared/data-access'
import { TFunction } from 'i18next'

const TRANSLATION_BASE_FORM_KEY = 'userArea.profile.actions.services.form'

export const rejectOptions = (t: TFunction) => [
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.pendingRescheduled`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.pendingRescheduled`),
    value: PolicySubStates.subscriptionPendingRescheduled,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.notLocatable`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.notLocatable`),
    value: PolicySubStates.subscriptionPendingNotLocatable,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.phoneError`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.phoneError`),
    value: PolicySubStates.subscriptionPendingPhoneError,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.noInterest`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.noInterest`),
    value: PolicySubStates.subscriptionPendingNoInterest,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.noShow`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.noShow`),
    value: PolicySubStates.subscriptionPendingNoShow,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.subscriptionPending`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.subscriptionPending`),
    value: PolicySubStates.subscriptionPending,
    disabled: true,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.subscriptionPendingNoAppointment`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.subscriptionPendingNoAppointment`),
    value: PolicySubStates.subscriptionPendingNoAppointment,
    disabled: true,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.rescheduledManagement`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.rescheduledManagement`),
    value: PolicySubStates.subscriptionPendingRescheduledManagement,
    disabled: true,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.pendingIssue`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.pendingIssue`),
    value: PolicySubStates.subscriptionPendingIssue,
    disabled: true,
  },
  {
    name: t(`${TRANSLATION_BASE_FORM_KEY}.pendingScheduled`),
    label: t(`${TRANSLATION_BASE_FORM_KEY}.pendingScheduled`),
    value: PolicySubStates.subscriptionPendingScheduled,
    disabled: true,
  },
]
