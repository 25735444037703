import tw from 'tailwind-styled-components'

export const ScheduleContainer = tw.div`
    py-4
`

export const formContainerClasses = `
    grid
    grid-cols-12
    gap-8
`
