import { SlotDate, DaySlot, SingleSchedule } from '../types'
import { AvailabilityCalendarButton } from './availability-calendar-hour-button'

export const AvailabilityCalendarBody = ({
  content,
  dateSelected,
  setDateSelected,
}: {
  content: SingleSchedule
  dateSelected: SlotDate
  setDateSelected: (dateSelected: SlotDate) => void
}) => {
  return (
    <tbody>
      <tr>
        {content.map(({ daySlots }: { daySlots: DaySlot[] }, index: number) => {
          return (
            <td className="p-2" key={index}>
              {daySlots.map((daySlot: DaySlot, index: number) => (
                <AvailabilityCalendarButton
                  key={index}
                  daySlot={daySlot}
                  dateSelected={dateSelected}
                  setDateSelected={setDateSelected}
                />
              ))}
            </td>
          )
        })}
      </tr>
    </tbody>
  )
}
