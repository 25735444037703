import { BUTTON_SIZES, Button } from '@shared/ui'
import { useLayoutContext } from '@sr/routes/common/layout-provider'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ActionsBlock,
  ActionsBlockContentTwoCols,
  ActionsBlockTitle,
} from '../../../styles'

type ReminderBlockProps = {
  sendEmail: () => void
  sendEmailTranslation?: string
}

export const ReminderBlock: FC<ReminderBlockProps> = ({
  sendEmail,
  sendEmailTranslation = 'send',
}) => {
  const { t } = useTranslation()
  const TRANSLATION_BASE_KEY = 'userArea.profile.actions.activity'

  const { isLoading } = useLayoutContext()

  return (
    <ActionsBlock>
      <ActionsBlockTitle>
        {t(`${TRANSLATION_BASE_KEY}.reminder.title`)}
      </ActionsBlockTitle>
      <ActionsBlockContentTwoCols>
        <Button
          size={BUTTON_SIZES.small}
          onClick={sendEmail}
          disabled={isLoading}
        >
          {t(`userArea.profile.welcomeEmail.${sendEmailTranslation}`)}
        </Button>
      </ActionsBlockContentTwoCols>
    </ActionsBlock>
  )
}
