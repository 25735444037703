import { FC } from 'react'
import { UserInfoContainer } from './styles'
import { UserInfoBox } from './user-info-box'
import { SRSelectOptions } from '../../sr-form-elements'
import { EditableType } from './enums'

export type UserInfoItem = {
  name?: string
  label: string
  value: string
  isEditing?: boolean
  hasToGrow?: boolean
  editableType?: EditableType
  selectOptions?: SRSelectOptions
  selectValue?: string | number
  isReadOnly?: boolean
  formattedValue?: string
  isTextarea?: boolean
}

type UserInfoProps = {
  data: UserInfoItem[]
}

export const UserInfo: FC<UserInfoProps> = ({ data }) => {
  if (!data) return null
  return (
    <UserInfoContainer>
      {data.map(
        (
          {
            name,
            label,
            value,
            isEditing = false,
            editableType = EditableType.input,
            hasToGrow = false,
            selectOptions = [],
            selectValue,
            formattedValue,
            isTextarea,
          },
          ix: number,
        ) => {
          return (
            <UserInfoBox
              key={`user-info-read-box-${ix}`}
              name={name}
              label={label}
              value={value}
              isEditing={isEditing}
              hasToGrow={hasToGrow}
              editableType={editableType}
              selectOptions={selectOptions}
              selectValue={selectValue}
              formattedValue={formattedValue}
              isTextarea={isTextarea}
            />
          )
        },
      )}
    </UserInfoContainer>
  )
}
