import tw from 'tailwind-styled-components'

export const ScheduleNoDigital = tw.div`
    py-9
    col-start-1
    col-end-5
`
export const CheckboxContainer = tw.div`
    flex
    gap-7
    py-6
`

export const inputClasses = `
    w-8
    h-8
`
